html, body {
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
}

body {
  flex-flow: column;
  max-width: 100%;
  height: 100%;
  display: flex;
}

header {
  background-image: url("main2_compr.f3e4b13b.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-flow: column;
  width: 100%;
  height: 70%;
  display: flex;
}

.logo {
  z-index: 0;
  background-image: url("tamar-logo.06029664.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 180px;
  height: 140px;
  display: block;
  position: relative;
  left: 5%;
}

.modal {
  display: none;
}

.modal-section {
  flex-flow: column;
  align-items: center;
  display: flex;
}

.modal-reveal {
  opacity: 1;
  z-index: 10;
  background-image: url("main2_compr.f3e4b13b.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 80vh;
  margin-top: 60px;
  font-size: 2rem;
  transition: all .5s ease-in;
  display: none;
  position: fixed;
}

.subscribenow-text {
  background-color: #ffffffa9;
  font-size: 2rem;
}

.subscribe-details {
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  height: 20rem;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}

.subscribe-label {
  background-color: #ffffffa9;
  border-radius: 5px;
  margin-right: 20px;
  padding: 5px;
  font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
  font-size: 1.5rem;
}

#subscribe-email {
  border: solid;
  width: 15rem;
  height: 1.5rem;
  margin-right: 20px;
}

#submit-subscribe {
  border: none;
  width: 5rem;
  height: 2rem;
}

#subscribe-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40vw;
  height: 40vh;
}

.overlay-reveal {
  opacity: 1;
  z-index: 9;
  background-color: #fdfbfbcc;
  width: 100%;
  height: 100%;
  transition: all .5s ease-in;
  position: fixed;
  top: 0;
}

.close-modal, .close-thanks {
  color: #000;
  cursor: pointer;
  background-color: #fff0;
  border: none;
  width: 20px;
  height: 20px;
  font-size: 1.2rem;
  position: absolute;
  top: -2rem;
  right: -1rem;
}

h1 {
  text-align: center;
  width: 95%;
  margin: 20px 0;
  font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
  font-size: 2.5rem;
}

.thanks {
  text-align: center;
  z-index: 10;
  background-color: #fffc;
  flex-flow: column wrap;
  align-items: center;
  width: 80vw;
  height: 30vh;
  margin-top: 60px;
  padding-top: 10px;
  font-size: 2rem;
  display: none;
  position: absolute;
  top: 20%;
}

.hide {
  display: none;
}

p {
  font-size: 1.3rem;
}

.menu {
  z-index: 10;
  flex-flow: column;
  width: 165px;
  height: 305px;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: ease;
  display: flex;
  position: relative;
  top: 10px;
  left: -200px;
}

.menu__ul {
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  width: 160px;
  max-width: 115%;
  height: 300px;
  display: flex;
  position: relative;
}

.main-nav-tab {
  background-color: #ffffffce;
  border-bottom: 1px solid;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 60px;
  display: flex;
}

.main-nav-tab:hover {
  cursor: pointer;
  background-color: #ffffffec;
}

.attrib {
  color: #000;
  flex-flow: row;
  align-items: center;
  width: 160px;
  padding-left: 10px;
  text-decoration: none;
  display: flex;
}

.categories-list {
  width: 165px;
  list-style: none;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: ease;
  position: absolute;
  top: 70px;
  left: -200px;
}

.category-item {
  background-color: #dd8aa8cf;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 60px;
  display: flex;
}

.category-item:hover {
  background-color: #caadb7cf;
}

.reveal {
  display: inline;
  top: 50px;
  left: 160px;
}

.category-text {
  justify-content: center;
  display: flex;
}

.icons-container {
  z-index: 10;
  align-self: flex-end;
  width: 10%;
  height: 7rem;
  margin-top: 1rem;
  position: absolute;
}

.menubars-svg {
  background-color: #ffffff9d;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  margin-bottom: 2rem;
  padding: 2px;
  display: flex;
}

.shoppingcart-container {
  z-index: 10;
  width: 35px;
  height: 35px;
}

.shoppingcart-mobile {
  fill: #000;
  background-color: #ffe23f96;
  border-radius: 5px;
  flex-flow: row;
  width: 30px;
  height: 30px;
  padding: 3px;
  display: flex;
}

.cart-number-mobile {
  text-align: center;
  z-index: 10;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  font-size: .6rem;
  display: flex;
  position: relative;
  top: 35%;
  left: 65%;
}

.toggle-svg {
  display: none;
}

#menubars-svg {
  fill: #000;
}

.shoppingcart-svg, .cart-number {
  display: none;
}

#login-tab {
  border-bottom: none;
}

#show-menu-button {
  display: none;
}

main {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: 25%;
}

.slider-container {
  width: 90%;
  margin-top: 9px;
}

.slider-wrapper .img-list .slider-image-item {
  object-fit: cover;
  height: 100%;
}

.slider-wrapper {
  flex-flow: row;
  justify-content: center;
  display: flex;
  overflow-x: hidden;
}

.img-list {
  flex-flow: row;
  width: 100%;
  display: flex;
}

.slider-image-item {
  width: 100%;
  padding: 5px;
  transition: transform 1s;
}

.slider_btns {
  cursor: pointer;
  color: #6c6c6c;
  text-align: center;
  background-color: #fffada99;
  border: .5px solid #c6aa767a;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 8%;
  height: 8%;
  font-size: 2rem;
  display: flex;
  position: absolute;
  top: 4%;
}

.slider-btn--left {
  left: 8%;
}

.slider-btn--right {
  right: 8%;
}

.slider_btns:hover {
  background-color: #e8e8e899;
}

.categories-container {
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 100%;
  margin-top: 50px;
  padding-top: 2rem;
  display: flex;
  box-shadow: 0 0 50px -10px #00000094;
}

.category-box {
  width: 90%;
  height: 20%;
  text-decoration: none;
}

.large-img-item {
  width: 100%;
}

.category-name {
  color: #fff;
  background-color: #0000001a;
  padding: 5px;
  font-size: 2rem;
  position: relative;
  bottom: 80px;
  left: 50px;
}

.large-img-item.hidden {
  opacity: 0;
  width: 100%;
  margin: 10px;
}

.go-to-top {
  background-color: #ffffffb6;
  border: 1px solid;
  width: 40%;
  margin: 30px;
  padding: 5px;
  position: relative;
}

.footer {
  background-color: #848484;
  flex-flow: wrap;
  max-width: 100vw;
  height: 100%;
  padding-top: .5rem;
  display: flex;
}

.columns-container {
  justify-content: space-between;
  width: 100%;
  height: 10%;
  display: flex;
}

.footer-right-column, .footer-middle-column, .footer-left-column {
  flex-flow: column wrap;
  align-items: center;
  width: 30%;
  height: 15rem;
  padding-top: 10px;
  display: flex;
}

.footer-middle-column {
  padding-right: 5px;
}

.footer-item {
  color: #fff;
  width: 9rem;
  margin-top: 10px;
  margin-bottom: 25px;
}

.attrib-footer {
  color: #000;
  border-bottom: .5px solid #424242;
  flex-flow: row;
  align-items: center;
  width: 100%;
  margin-bottom: 6%;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: .7rem;
  text-decoration: none;
  display: flex;
}

.rights-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;
  display: flex;
}

.rights-text {
  color: #fff;
  text-align: center;
  font-size: .9rem;
}
/*# sourceMappingURL=index.22fbffca.css.map */
